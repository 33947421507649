import React, {createContext, useState, useRef} from 'react'
export const AppContext = createContext()

function Context({children}) {
	const [shareYourInterests, setShareYourInterests] = useState(true)
	const [searchOpportunities, setSearchOpportunities] = useState(false)
	const [selectedUpcomingCluster, setSelectedUpcomingCluster] = useState([])
	const selectedSubject = useRef([])
	const selectedData = useRef([])
	return (
		<AppContext.Provider
			value={{
				shareYourInterests,
				setShareYourInterests,
				searchOpportunities,
				setSearchOpportunities,
				selectedSubject,
				selectedData,
				selectedUpcomingCluster,
				setSelectedUpcomingCluster,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export default Context
