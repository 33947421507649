import {useHits} from 'react-instantsearch-hooks-web'
import {useState, useContext, useEffect} from 'react'
import {
	Grid,
	Card,
	CardActionArea,
	CardHeader,
	CardContent,
	Typography,
	Container,
	Box,
	Pagination,
	Button,
	Alert,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import getFormUrl, {
	clusterList,
	formBaseUrl,
	keyMapping,
} from './helpers/formUrlGenerator'
import {useCookies} from 'react-cookie'
import useCurrentSelection from './helpers/useCurrentSelection'
import {AppContext} from './Context'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
function OtherOpporCard({onClick, onSelectionChange}) {
	const {selectedData, selectedUpcomingCluster} = useContext(AppContext)

	const separateUpcomingSelections = () => {
		const city = selectedData.current.city?.value
		const clusters = selectedUpcomingCluster
			.filter(item => item.startsWith('upcoming cluster:'))
			.map(item => item.replace('upcoming cluster:', '').trim())
		return {city, clusters}
	}

	const {city, clusters} = separateUpcomingSelections()

	useEffect(() => {
		onSelectionChange({city, clusters})
	}, [selectedUpcomingCluster, onSelectionChange, city, clusters])

	return (
		<Grid item xs={1}>
			<Card
				variant="outlined"
				sx={{height: '100%', cursor: 'pointer', position: 'relative'}}
				elevation={0}
				onClick={onClick}
			>
				<CardActionArea>
					<CardHeader
						title="Looking for Other Subjects?"
						titleTypographyProps={{
							variant: 'subtitle1',
							textTransform: 'uppercase',
						}}
						avatar={<ArrowForwardIcon sx={{color: '#0EC0E2'}} />}
					/>
					<CardContent>
						<Typography variant="body2" color="text.secondary">
							{clusters.length > 0 && (
								<Typography
									variant="body2"
									color="text.secondary"
								>
									Join the waitlist for these clusters:{' '}
									{clusters.join(', ')}
								</Typography>
							)}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	)
}
function Opportunities() {
	const {selectedSubject, selectedData, selectedUpcomingCluster} =
		useContext(AppContext)
	let obj = {
		mode: null,
		city: null,
		cluster: [],
		availability: [],
		subject1: [],
		subject2: [],
		ref_code: null,
	}
	const [errorMessage, setErrorMessage] = useState(null)
	const [cardPagination, setCardPagination] = useState({
		startPage: 0,
		endPage: 8,
		pageValue: 1,
	})
	const [, setChecked] = useState(false)
	const sections = useCurrentSelection()
	const {hits} = useHits()
	const [cookie] = useCookies(['ref_code'])
	const errorMsgDuration = 5000
	let cardPaginationPages
	const [upcomingSelections, setUpcomingSelections] = useState({
		city: null,
		clusters: [],
	})
	function validSubjectCheck() {
		if (selectedSubject.current.length === 0) {
			setErrorMessage(
				'Please select at least 1 subject preference. You can modify it later too if needed.'
			)
			return false
		}
		if (selectedSubject.current.length > 2) {
			setErrorMessage(
				'Sorry, You can only select a maximum of 2 subjects preferences.'
			)
			return false
		}
		return true
	}

	function urlBuilder() {
		obj = {
			mode: sections.mode.value,
			city: sections.mode.value === 'Online' ? null : sections.city.value,
			cluster:
				sections.mode.value === 'Online'
					? null
					: selectedData.current.cluster.value.join(';'),
			availability: selectedData.current.availability.value,
			subject1: selectedSubject.current[0],
			subject2: selectedSubject.current[1],
			ref_code: cookie.ref_code,
		}
	}
	const subjectsList = [
		'English',
		'Mathematics',
		'Science',
		'Social Studies',
		'Art',
		'Craft',
		'Music',
		'Drama',
		'Dance',
		'Physical Education',
		'Socio Emotional Learning',
		'General Knowledge',
		'Digital Literacy',
		'Financial Literacy',
		'Career Mentoring',
	]
	let unavailableSubjects = []
	// grouped city-cluster And availability for all same subjects hits (object) for (Subject-card)
	// if subject have diffrent city-cluster And availability  Return one grouped  (customHits)
	function customHits(hits) {
		let subjects = hits.map(item => item.subject)
		let uniqueSubjectList = subjects.filter(onlyUnique)
		unavailableSubjects = subjectsList.filter(
			subject => !uniqueSubjectList.includes(subject)
		)
		let subjectList
		let filterHits = []
		let filterHitsObj = {}
		uniqueSubjectList.forEach(item => {
			subjectList = {...subjectList, [item]: 0}
		})
		hits.forEach(item => {
			if (subjectList[item.subject] === 0) {
				subjectList[item.subject] = subjectList[item.subject] + 1
				// filterHits.push(item)
				filterHitsObj[item.subject] = item
			}
		})
		hits.forEach((item, index) => {
			if (filterHitsObj[item.subject].subject === item.subject) {
				if (!filterHitsObj[item.subject].cluster.includes(item.cluster))
					filterHitsObj[item.subject] = {
						...filterHitsObj[item.subject],
						cluster:
							filterHitsObj[item.subject].cluster +
							', ' +
							item.cluster,
					}
				item.availability.forEach(_item => {
					if (
						!filterHitsObj[item.subject].availability.includes(
							_item
						)
					)
						filterHitsObj[item.subject] = {
							...filterHitsObj[item.subject],
							availability: [
								...filterHitsObj[item.subject].availability,
								_item,
							],
						}
				})
			}
		})

		filterHits = Object.keys(filterHitsObj).map(function (key) {
			return filterHitsObj[key]
		})

		//utill fun
		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index
		}
		// Subjects card custom cardPagination
		cardPaginationPages = filterHits.length
		if (cardPaginationPages > 8) {
			return filterHits.slice(
				cardPagination.startPage,
				cardPagination.endPage
			)
		} else {
			if (cardPagination.pageValue > 1)
				setCardPagination({startPage: 0, endPage: 8, pageValue: 1})
			return filterHits.slice(0, 8)
		}
		// return filterHits (if card pagination don't need)
	}
	const [openDialog, setOpenDialog] = useState(false)

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleRegisterNewSubject = () => {
		const city = selectedUpcomingCluster.find(item =>
			item.startsWith('Upcoming city:')
		)
		const cluster = selectedUpcomingCluster
			.filter(item => item.startsWith('upcoming cluster:'))
			.map(item => item.replace('upcoming cluster:', '').trim())

		let formUrl = formBaseUrl

		if (city) {
			const cityParam = city.replace('Upcoming city:', '').trim()
			formUrl += `?${keyMapping.mode}=Offline&${
				keyMapping.city
			}=${encodeURIComponent(cityParam)}`
		}

		if (cluster.length > 0) {
			const selectedCity = selectedData.current.city?.value
			if (selectedCity) {
				const clusterParam = cluster.join(';')
				const clusterKey = clusterList[selectedCity]
				formUrl += `?${keyMapping.mode}=Offline&${
					keyMapping.city
				}=${encodeURIComponent(
					selectedCity
				)}&${clusterKey}=${encodeURIComponent(clusterParam)}`
			}
		}

		if (cookie.ref_code) {
			formUrl += city || cluster.length > 0 ? '&' : '?'
			formUrl += `${keyMapping.ref_code}=${encodeURIComponent(
				cookie.ref_code
			)}`
		}

		window.open(formUrl, '_self')
		handleCloseDialog()
	}
	const availabilityMap = {
		'Weekday (Anytime between 7:00 AM - 12.00 PM)': ['Weekday', 'morning'],
		'Weekday (Anytime between 12:00 PM - 3:00 PM)': [
			'Weekday',
			'afternoon',
		],
		'Weekday (Anytime between 3:00 PM - 6:00 PM)': ['Weekday', 'evening'],
		'Weekday (Anytime between 6:00 PM - 9:00 PM)': ['Weekday', 'evening'],
		'Weekend (Saturdays - for a few hours)': ['Weekend', 'morning'],
	}
	return (
		sections.availability.hasSelected && (
			<>
				<Container disableGutters sx={{mb: 4}}>
					<Box textAlign="center" sx={{my: 4}}>
						{errorMessage && (
							<Alert sx={{p: '0px', m: '0px'}} severity="warning">
								{errorMessage}
							</Alert>
						)}
					</Box>
					<Grid
						container
						spacing={2}
						columns={{xs: 1, md: 3, xl: 4}}
						alignItems="stretch"
					>
						{customHits(hits).map(hit => {
							const groupedAvailability =
								hit.availability?.reduce((acc, timeSlot) => {
									const [day, time] =
										availabilityMap[timeSlot.trim()] || []
									if (day && time) {
										if (!acc[day]) acc[day] = new Set()
										acc[day].add(time)
									}
									return acc
								}, {})

							const formattedAvailability = Object.entries(
								groupedAvailability || {}
							)
								.map(
									([day, times]) =>
										`${day} - ${Array.from(times).join(
											', '
										)}`
								)
								.join('\n')
							return (
								<Grid item xs={1} key={hit.objectID}>
									<Card
										key={hit.objectID}
										variant="outlined"
										sx={{height: 1}}
										elevation={0}
										style={{
											backgroundColor:
												selectedSubject.current[0] ===
													hit.subject ||
												selectedSubject.current[1] ===
													hit.subject
													? '#e0e0e0'
													: 'white',
											// backgroundColor: "grey"
										}}
										onClick={() => {
											if (
												selectedSubject.current[0] !==
													hit.subject &&
												selectedSubject.current[1] !==
													hit.subject &&
												selectedSubject.current.length <
													2
											) {
												setErrorMessage(null)
												selectedSubject.current.push(
													hit.subject
												)
												if (
													selectedSubject.current
														.length === 1 &&
													hits.length > 1
												)
													setErrorMessage(
														'You may also choose a 2nd subject preference if you wish to'
													)
											} else {
												selectedSubject.current =
													selectedSubject.current.filter(
														function (value) {
															return (
																value !==
																hit.subject
															)
														}
													)
											}
											if (
												selectedSubject.current
													.length === 2 &&
												selectedSubject.current[0] !==
													hit.subject &&
												selectedSubject.current[1] !==
													hit.subject
											) {
												setErrorMessage(
													'Sorry, You can select only 2 subject preferences'
												)
												setTimeout(
													() => setErrorMessage(null),
													errorMsgDuration
												)
											}
											setChecked(c => !c)
										}}
									>
										<CardActionArea>
											<CardHeader
												title={hit.subject}
												subheader={
													sections.mode.value ===
													'Offline'
														? `${hit.cluster}`
														: ''
												}
												titleTypographyProps={{
													variant: 'subtitle1',
													textTransform: 'uppercase',
													fontWeight: 600,
													color: 'text.primary',
													sx: {
														fontSize: '1rem',

														opacity: 0.9,
													},
												}}
												avatar={
													<CheckCircleIcon
														sx={{
															color:
																selectedSubject
																	.current[0] ===
																	hit.subject ||
																selectedSubject
																	.current[1] ===
																	hit.subject
																	? '#0EC0E2'
																	: '#dfe6eb',
														}}
													/>
												}
											/>
											<CardContent sx={{pt: 0}}>
												<Typography
													variant="body2"
													color="text.secondary"
												>
													{formattedAvailability}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							)
						})}
					</Grid>
					<Grid
						container
						spacing={2}
						columns={{xs: 1, md: 3, xl: 4}}
						alignItems="stretch"
						sx={{mt: 1}}
					>
						<OtherOpporCard
							onClick={handleOpenDialog}
							onSelectionChange={setUpcomingSelections}
						/>
					</Grid>
					<Box sx={{my: 4}}>
						<Pagination
							count={Math.ceil(cardPaginationPages / 8)}
							page={
								cardPaginationPages > 8
									? cardPagination.pageValue
									: 1
							}
							onChange={(e, value) => {
								if (value === cardPagination.pageValue) return
								if (value > cardPagination.pageValue) {
									setCardPagination({
										startPage: cardPagination.startPage + 8,
										endPage: cardPagination.endPage + 8,
										pageValue: value,
									})
								} else {
									setCardPagination({
										startPage: cardPagination.startPage - 8,
										endPage: cardPagination.endPage - 8,
										pageValue: value,
									})
								}
							}}
						/>
					</Box>
					<Box textAlign="center" sx={{my: 4}}>
						{window.screen.width >= 800
							? null
							: errorMessage && (
									<Alert
										sx={{p: '0px', m: '0px'}}
										severity="warning"
									>
										{errorMessage}
									</Alert>
							  )}
						<Typography>
							Please note that expressing interest in a specific
							opportunity does not assure allotment to the same.
						</Typography>
						<Button
							sx={{width: 200, p: 1, m: 2}}
							variant="outlined"
							onClick={() => {
								if (validSubjectCheck()) {
									urlBuilder()
									window.open(getFormUrl(obj), '_self')
								}
							}}
						>
							Apply Now{' '}
						</Button>
					</Box>
				</Container>
				<Dialog open={openDialog} onClose={handleCloseDialog}>
					<DialogTitle
						style={{backgroundColor: '#f3f4f6', fontWeight: 'bold'}}
					>
						{unavailableSubjects.length > 0
							? 'Other Subjects'
							: 'Continue to Apply?'}
					</DialogTitle>

					<DialogContent style={{paddingTop: '16px'}}>
						{unavailableSubjects.length > 0 ? (
							<div>
								<p>
									If none of the available subjects interest
									you, you may proceed with your application
									to be considered for the additional subjects
									listed below. Please note that these are
									currently unavailable and may take time to
									open.
								</p>
								<ul>
									{unavailableSubjects.map(subject => (
										<li key={subject}>{subject}</li>
									))}
								</ul>
							</div>
						) : (
							<p>
								Currently, there are no available opportunities
								for your selected preference. However, you can
								continue with your application, and we'll notify
								you as soon as a matching opportunity opens up.
							</p>
						)}
					</DialogContent>

					<DialogActions>
						<Button
							onClick={handleCloseDialog}
							style={{color: '#6b7280'}}
						>
							Cancel
						</Button>
						<Button
							onClick={handleRegisterNewSubject}
							color="primary"
							style={{backgroundColor: '#3b82f6', color: '#fff'}}
						>
							Notify Me!
						</Button>
					</DialogActions>
				</Dialog>
			</>
		)
	)
}

export default Opportunities

//Pagination base on algolia react-instantsearch-hooks-web,
// can not use with grouped city-cluster And availability for all same subjects hits (object) for (Subject-card), to use (define hitsPerPage={8} in app.js)
// function HitPages() {
// 	const pages = usePagination()
// 	const {nbPages, refine, currentRefinement, canRefine} = pages

// 	if (canRefine)
// 		return (
// 			<Pagination
// 				count={nbPages}
// 				page={currentRefinement + 1}
// 				onChange={(e, value) => refine(value - 1)}
// 			/>
// 		)
// 	else return null
// }
