import {
	Box,
	Button,
	Container,
	Menu,
	MenuItem,
	AppBar,
	IconButton,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import {Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import {formBaseUrl, keyMapping} from './helpers/formUrlGenerator'
import {useCookies} from 'react-cookie'
import {AppContext} from './Context'

import React, {useContext, useState, useEffect} from 'react'
const StyledAppBar = styled(AppBar)({
	backgroundColor: 'black',
})

const StyledButton = styled(Button)(({theme, active}) => ({
	color: '#0ec4e2',
	backgroundColor: 'transparent',
	'&:hover': {
		backgroundColor: 'white',
	},
	margin: theme.spacing(0, 1),
	padding: theme.spacing(1, 2),
}))
function Header() {
	const {
		shareYourInterests,
		setShareYourInterests,
		searchOpportunities,
		setSearchOpportunities,
	} = useContext(AppContext)
	const [cookie] = useCookies(['ref_code'])
	const [anchorElNav, setAnchorElNav] = useState(null)

	const handleOpenNavMenu = event => {
		setAnchorElNav(event.currentTarget)
	}

	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	const handleSearchClick = () => {
		setShareYourInterests(true)
		setSearchOpportunities(true)
		handleCloseNavMenu()
	}
	useEffect(() => {
		handleSearchClick()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleShareClick = () => {
		if (shareYourInterests && searchOpportunities) {
			window.open(
				`${formBaseUrl}${
					cookie.ref_code === undefined || null
						? ''
						: `?${keyMapping.ref_code}=${cookie.ref_code}`
				}`,
				'_self'
			)
			return
		}
		setShareYourInterests(false)
		setSearchOpportunities(false)
		handleCloseNavMenu()
	}
	return (
		<>
			<StyledAppBar position="static">
				<Container maxWidth="lg">
					<Toolbar disableGutters>
						<ButtonBase
							component="a"
							href="https://teachforindia.org/volunteer"
						>
							<Box
								sx={{
									height: '56px',
									padding: 0,
								}}
								component="img"
								src="/logo.png"
							></Box>
						</ButtonBase>
						<Typography
							variant="h6"
							noWrap
							component="div"
							sx={{
								flexGrow: 1,
								display: 'flex',
								fontSize: {xs: '0.875rem', md: '1.1rem'},
							}}
						>
							Volunteering Opportunities
						</Typography>
					</Toolbar>
				</Container>
			</StyledAppBar>
			<Container
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					paddingY: 4,
				}}
				maxWidth="lg"
			>
				<Box
					sx={{
						backgroundColor: '#f5f5f5',
						padding: '30px',
						borderRadius: '12px',
						boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
						maxWidth: {xs: '100%', md: '75%'},
						textAlign: 'center',
					}}
				>
					<Typography
						variant="h5"
						sx={{
							fontWeight: 'bold',
							color: '#089eb6',
							marginBottom: '12px',
						}}
					>
						Discover Your Ideal Volunteering Opportunity!
					</Typography>
					<Typography
						variant="body1"
						sx={{
							color: '#333',
							fontSize: '1rem',
							lineHeight: 1.6,
							marginTop: '16px',
						}}
					>
						A. Enter your preferences below to view current
						volunteering opportunities at Teach For India.
					</Typography>
					<Typography
						variant="body1"
						sx={{
							color: '#333',
							fontSize: '1rem',
							lineHeight: 1.6,
							marginTop: '8px',
						}}
					>
						B. Please explore the options thoroughly and select the
						one that best aligns with your interests.
					</Typography>
					<Typography
						variant="body1"
						sx={{
							color: '#333',
							fontSize: '1rem',
							lineHeight: 1.6,
							marginTop: '8px',
						}}
					>
						C. If none of the available opportunities match your
						preferences, you can select a waitlist option and
						proceed with your application.
					</Typography>
				</Box>
			</Container>
		</>
	)
}
export default Header
