import {
	Box,
	Checkbox,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Typography,
	Link,
	Snackbar,
	ListSubheader,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Alert,
} from '@mui/material'
import {
	useRefinementList,
	useMenu,
	useClearRefinements,
} from 'react-instantsearch-hooks-web'
import {useCookies} from 'react-cookie'
import Opportunities from './Opportunities'
import useCurrentSelection from './helpers/useCurrentSelection'
import {formBaseUrl, keyMapping, clusterList} from './helpers/formUrlGenerator'
import {useEffect, useContext, useState} from 'react'
import {AppContext} from './Context'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'
import cityClusterData from './city_clusters.json'
function Filter() {
	const [cookie] = useCookies(['ref_code'])
	const {shareYourInterests, searchOpportunities} = useContext(AppContext)
	return (
		<Container sx={{mt: '40px', mb: '90px'}} maxWidth="lg">
			<Box sx={{display: 'flex', flexDirection: 'column'}}>
				{searchOpportunities && (
					<>
						<FilterBar />
						<Opportunities />
					</>
				)}
			</Box>
			{!shareYourInterests && (
				<Typography textAlign="center" sx={{mt: '50px'}}>
					Seems you haven't explored the available opportunities yet.
					If you still want to proceed,{' '}
					<Link
						component="a"
						href={`${formBaseUrl}${
							cookie.ref_code === undefined || null
								? ''
								: `?${keyMapping.ref_code}=${cookie.ref_code}`
						}`}
					>
						Click here to record your interest.
					</Link>
				</Typography>
			)}
		</Container>
	)
}
export default Filter

function FilterBar() {
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState('')
	const [showError] = useState(false)
	let {mode, city, cluster, availability} = useCurrentSelection()
	const {selectedSubject, selectedData} = useContext(AppContext)
	let displayMessage = null
	let filterMenuConfig = [{type: 'menu', attribute: 'mode'}]

	if (mode.hasSelected && mode.value === 'Offline') {
		filterMenuConfig.push({type: 'menu', attribute: 'city'})
	}

	if (city.hasSelected) {
		filterMenuConfig.push({type: 'list', attribute: 'cluster'})
	}
	if (
		mode.hasSelected &&
		(mode.value === 'Online' ||
			(mode.value === 'Offline' &&
				cluster.hasSelected &&
				selectedData.current.cluster.value?.length <= 3))
	) {
		filterMenuConfig.push({type: 'list', attribute: 'availability'})
	}
	let filterMenus = filterMenuConfig.map(menu => {
		let component
		if (menu.type === 'menu') {
			component = <FilterMenu attribute={menu.attribute} />
		} else {
			component = <FilterList attribute={menu.attribute} />
		}
		return (
			<Grid item xs={1} key={menu.attribute}>
				{component}
			</Grid>
		)
	})
	useEffect(() => {
		if (mode.hasSelected) {
			window.scrollBy(0, window.innerHeight)
		}
	}, [mode.hasSelected])

	switch (filterMenus.length) {
		case 1:
			displayMessage =
				"Let's get started by selecting your volunteering preferences for mode."
			selectedSubject.current = []
			break
		case 2:
			displayMessage = 'Now select your city volunteering preferences.'
			if (mode.value === 'Online')
				displayMessage =
					'Now select your availability volunteering preferences for mode.'
			if (mode.value === 'Online' && availability.hasSelected)
				displayMessage =
					'Here is a list of Opportunities based on selected preferences. Select Opportunities  (Max. 2 Subjects) and Apply.'
			selectedSubject.current = []
			break
		case 3:
			displayMessage =
				'Now select your city cluster volunteering preferences.'
			selectedSubject.current = []
			break
		case 4:
			displayMessage =
				'Here is a list of Opportunities based on selected preferences. Select Opportunities  (Max. 2 Subjects) and Apply.'
			if (availability.hasSelected === false)
				displayMessage =
					'Now select your availability volunteering preferences for mode.'
			selectedSubject.current = []
			break
		default:
			displayMessage = null
	}

	if (cluster.hasSelected && selectedData.current.cluster.value?.length > 3) {
		displayMessage =
			'Sorry, you can shortlist a maximum of 3 clusters only.'
	}
	useEffect(() => {
		if (displayMessage) {
			setSnackbarMessage(displayMessage)
			setOpenSnackbar(true)
		}
	}, [displayMessage])

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenSnackbar(false)
	}
	return (
		<>
			{displayMessage && (
				<Alert
					sx={{
						mb: '20px',
					}}
					severity="info"
				>
					{displayMessage}
				</Alert>
			)}
			<Box
				container
				sx={{
					display: 'flex',
					flexDirection: {xs: 'column', sm: 'column', md: 'row'},
					maxWidth: {md: '94%'},
				}}
			>
				<Grid
					container
					columns={{xs: 1, sm: 2, md: 4}}
					spacing={{xs: 2, sm: 3, md: 1}}
				>
					{filterMenus}
				</Grid>
				{availability.hasSelected && <CustomClearRefinements />}
			</Box>
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				message={
					<span style={{display: 'flex', alignItems: 'center'}}>
						{showError ? (
							<ErrorIcon
								style={{marginRight: '8px', color: '#f44336'}}
							/>
						) : (
							<InfoIcon
								style={{marginRight: '8px', color: '#2196f3'}}
							/>
						)}
						{snackbarMessage}
					</span>
				}
				sx={{
					bottom: {xs: '80px', sm: '100px', md: '60px', lg: '120px'},
				}}
			/>
		</>
	)
}

function FilterList({attribute}) {
	const {items, refine} = useRefinementList({attribute})
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState('')
	const {selectedData} = useContext(AppContext)
	const handleRefine = value => {
		if (attribute === 'cluster') {
			const currentSelectedClusters =
				selectedData.current.cluster.value || []
			if (currentSelectedClusters.includes(value)) {
				refine(value)
			} else if (currentSelectedClusters.length < 3) {
				refine(value)
			} else {
				setSnackbarMessage(
					'Sorry, you can shortlist a maximum of 3 clusters only.'
				)
				setOpenSnackbar(true)
			}
		} else {
			refine(value)
		}
	}
	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenSnackbar(false)
	}
	return (
		<>
			<SelectField
				attribute={attribute}
				value={items.filter(v => v.isRefined).map(v => v.value)}
				multiple={true}
				items={items}
				refine={handleRefine}
			/>
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				message={
					<span style={{display: 'flex', alignItems: 'center'}}>
						<ErrorIcon
							style={{marginRight: '8px', color: '#f44336'}}
						/>
						{snackbarMessage}
					</span>
				}
				sx={{
					bottom: {xs: '24px', sm: '40px'},
				}}
			/>
		</>
	)
}

function FilterMenu({attribute}) {
	const {items, refine} = useMenu({attribute})
	return (
		<SelectField
			attribute={attribute}
			value={items.find(v => v.isRefined)?.label ?? ''}
			multiple={false}
			items={items}
			refine={refine}
		/>
	)
}

function CustomClearRefinements(props) {
	const {refine} = useClearRefinements(props)
	const {selectedSubject, setSelectedUpcomingCluster} = useContext(AppContext)
	return (
		<Button
			sx={{
				my: '10px',
				marginLeft: '10px',
			}}
			variant="outlined"
			onClick={() => {
				selectedSubject.current = []
				setSelectedUpcomingCluster([])

				refine()
			}}
		>
			Reset
		</Button>
	)
}

function SelectField({multiple, attribute, value, items, refine}) {
	const [isOpen, setIsOpen] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const {selectedUpcomingCluster, setSelectedUpcomingCluster} =
		useContext(AppContext)
	const [cookie] = useCookies(['ref_code'])
	const {selectedData} = useContext(AppContext)

	const isClusterAttribute = attribute === 'cluster'
	const selectedCity = selectedData.current.city?.value

	const availableItems = items.map(item => item.label)
	const allItems =
		isClusterAttribute && selectedCity
			? cityClusterData[selectedCity]
			: attribute === 'city'
			? Object.keys(cityClusterData)
			: []

	const upcomingItems = allItems.filter(
		item => !availableItems.includes(item)
	)

	const allAvailabilityOptions = [
		'Weekday (Anytime between 7:00 AM - 12.00 PM)',
		'Weekday (Anytime between 12:00 PM - 3:00 PM)',
		'Weekday (Anytime between 3:00 PM - 6:00 PM)',
		'Weekday (Anytime between 6:00 PM - 9:00 PM)',
		'Weekend (Saturdays - for a few hours)',
	]
	const upcomingAvailabilityItems = allAvailabilityOptions.filter(
		option => !availableItems.includes(option)
	)

	const handleOpenDialog = () => setOpenDialog(true)
	const handleCloseDialog = () => setOpenDialog(false)

	const handleUpcomingSelect = (item, type) => {
		const prefix = type === 'city' ? 'Upcoming city:' : 'upcoming cluster:'
		const formattedItem = `${prefix} ${item}`

		let updatedSelection
		if (type === 'city') {
			updatedSelection = selectedUpcomingCluster.filter(
				selected => !selected.startsWith('Upcoming city:')
			)
			updatedSelection.push(formattedItem)
			handleOpenDialog()
		} else {
			updatedSelection = selectedUpcomingCluster.includes(formattedItem)
				? selectedUpcomingCluster.filter(
						selected => selected !== formattedItem
				  )
				: [...selectedUpcomingCluster, formattedItem]
		}

		setSelectedUpcomingCluster(updatedSelection)
	}

	const handleUpcomingSelectAvailability = item => {
		const prefix = `upcoming availability:`
		const formattedItem = `${prefix} ${item}`

		const updatedSelection = selectedUpcomingCluster.includes(formattedItem)
			? selectedUpcomingCluster.filter(
					selected => selected !== formattedItem
			  )
			: [...selectedUpcomingCluster, formattedItem]
		setSelectedUpcomingCluster(updatedSelection)
	}

	const handleRegisterNewSubject = () => {
		const city = selectedUpcomingCluster.find(item =>
			item.startsWith('Upcoming city:')
		)
		const cluster = selectedUpcomingCluster
			.filter(item => item.startsWith('upcoming cluster:'))
			.map(item => item.replace('upcoming cluster:', '').trim())

		const availability = selectedUpcomingCluster
			.filter(item => item.startsWith('upcoming availability:'))
			.map(item => item.replace('upcoming availability:', '').trim())

		let formUrl = formBaseUrl

		if (city) {
			const cityParam = city.replace('Upcoming city:', '').trim()
			formUrl += `?${keyMapping.mode}=Offline&${
				keyMapping.city
			}=${encodeURIComponent(cityParam)}`
		}

		if (cluster.length > 0) {
			const selectedCity = selectedData.current.city?.value
			if (selectedCity) {
				const clusterParam = cluster.join(';')
				const clusterKey = clusterList[selectedCity]
				formUrl += `?${keyMapping.mode}=Offline&${
					keyMapping.city
				}=${encodeURIComponent(
					selectedCity
				)}&${clusterKey}=${encodeURIComponent(clusterParam)}`
			}
		}

		if (availability.length > 0) {
			const availabilityParam = availability.join(';')
			const selectedCity = selectedData.current.city?.value
			const selectedCluster = selectedData.current.cluster?.value
			if (selectedCity) {
				const clusterParam = selectedCluster.join(';')
				const clusterKey = clusterList[selectedCity]
				formUrl += `?${keyMapping.mode}=Offline&${
					keyMapping.city
				}=${encodeURIComponent(
					selectedCity
				)}&${clusterKey}=${encodeURIComponent(clusterParam)}&${
					keyMapping.availability
				}=${encodeURIComponent(availabilityParam)}`
			}
		}

		if (cookie.ref_code) {
			formUrl +=
				city || cluster.length > 0 || availability.length > 0
					? '&'
					: '?'
			formUrl += `${keyMapping.ref_code}=${encodeURIComponent(
				cookie.ref_code
			)}`
		}

		window.open(formUrl, '_self')
		handleCloseDialog()
	}

	const handleClose = () => {
		if (
			attribute === 'cluster' &&
			selectedUpcomingCluster.some(item =>
				item.includes('upcoming cluster:')
			) &&
			!selectedData.current.cluster?.value
		) {
			handleOpenDialog()
		} else if (
			attribute === 'availability' &&
			selectedUpcomingCluster.some(item =>
				item.includes('upcoming availability:')
			) &&
			!selectedData.current.availability?.value
		) {
			handleOpenDialog()
		}
		setIsOpen(false)
	}

	const handleOpen = () => {
		setIsOpen(true)
	}

	return (
		<FormControl fullWidth>
			<InputLabel
				id={`${attribute}-menu`}
				style={{textTransform: 'capitalize'}}
			>
				{attribute}
			</InputLabel>
			<Select
				labelId={`${attribute}-menu`}
				id={`${attribute}-select`}
				value={value}
				renderValue={selected =>
					Array.isArray(selected) ? selected.join(', ') : selected
				}
				label={attribute}
				multiple={multiple}
				open={isOpen}
				onClose={handleClose}
				onOpen={handleOpen}
				sx={{
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.23)',
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: 'primary.main',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'rgba(0, 0, 0, 0.87)',
					},
					'& .MuiSelect-select': {
						paddingRight: '32px !important',
					},
				}}
			>
				<ListSubheader
					style={{
						fontWeight: 'bold',
						color: '#6b7280',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<span>Available</span>
					<Button
						onClick={handleClose}
						style={{
							minWidth: 'auto',
							padding: '4px',
							color: '#6b7280',
						}}
					>
						<CloseIcon />
					</Button>
				</ListSubheader>
				{items.map(item => (
					<MenuItem
						key={item.label}
						value={item.label}
						onClick={() => refine(item.value)}
						style={{
							padding: '8px 16px',
							'&:hover': {backgroundColor: '#f3f4f6'},
						}}
					>
						{multiple && (
							<Checkbox
								checked={item.isRefined}
								style={{marginRight: '8px'}}
								color="primary"
							/>
						)}
						<span style={{flexGrow: 1}}>{item.label}</span>
						<span style={{color: '#6b7280', fontSize: '0.875rem'}}>
							({item.count})
						</span>
					</MenuItem>
				))}
				{attribute === 'city' &&
					!selectedCity &&
					upcomingItems.length > 0 && (
						<>
							<ListSubheader
								style={{
									fontWeight: 'bold',
									color: '#6b7280',
									marginTop: '8px',
								}}
							>
								Join the waitlist
							</ListSubheader>
							{upcomingItems.map(item => (
								<MenuItem
									key={item}
									value={item}
									onClick={() =>
										handleUpcomingSelect(item, attribute)
									}
									style={{
										padding: '8px 16px',
										'&:hover': {backgroundColor: '#f3f4f6'},
									}}
								>
									{multiple && (
										<Checkbox
											checked={selectedUpcomingCluster.includes(
												`upcoming ${attribute}: ${item}`
											)}
											style={{marginRight: '8px'}}
											color="primary"
										/>
									)}
									<span style={{flexGrow: 1}}>{item}</span>
								</MenuItem>
							))}
						</>
					)}
				{attribute === 'cluster' && upcomingItems.length > 0 && (
					<>
						<ListSubheader
							style={{
								fontWeight: 'bold',
								color: '#6b7280',
								marginTop: '8px',
							}}
						>
							Join the waitlist
						</ListSubheader>
						{upcomingItems.map(item => (
							<MenuItem
								key={item}
								value={item}
								onClick={() =>
									handleUpcomingSelect(item, attribute)
								}
								style={{
									padding: '8px 16px',
									'&:hover': {backgroundColor: '#f3f4f6'},
								}}
							>
								{multiple && (
									<Checkbox
										checked={selectedUpcomingCluster.includes(
											`upcoming ${attribute}: ${item}`
										)}
										style={{marginRight: '8px'}}
										color="primary"
									/>
								)}
								<span style={{flexGrow: 1}}>{item}</span>
							</MenuItem>
						))}
					</>
				)}
				{attribute === 'availability' &&
					upcomingAvailabilityItems.length > 0 && (
						<>
							<ListSubheader
								style={{
									fontWeight: 'bold',
									color: '#6b7280',
									marginTop: '8px',
								}}
							>
								Join the waitlist
							</ListSubheader>
							{upcomingAvailabilityItems.map(item => (
								<MenuItem
									key={item}
									value={item}
									onClick={() =>
										handleUpcomingSelectAvailability(item)
									}
									style={{
										padding: '8px 16px',
										'&:hover': {backgroundColor: '#f3f4f6'},
									}}
								>
									{multiple && (
										<Checkbox
											checked={selectedUpcomingCluster.includes(
												`upcoming availability: ${item}`
											)}
											style={{marginRight: '8px'}}
											color="primary"
										/>
									)}
									<span style={{flexGrow: 1}}>{item}</span>
								</MenuItem>
							))}
						</>
					)}
			</Select>
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogTitle
					style={{backgroundColor: '#f3f4f6', fontWeight: 'bold'}}
				>
					Continue to Apply?
				</DialogTitle>
				<DialogContent style={{paddingTop: '16px'}}>
					<p>
						Currently, there are no available opportunities for your
						selected preference. However, you can continue with your
						application, and we'll notify you as soon as a matching
						opportunity opens up.
					</p>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseDialog}
						style={{color: '#6b7280'}}
					>
						Cancel
					</Button>
					<Button
						onClick={handleRegisterNewSubject}
						color="primary"
						style={{backgroundColor: '#3b82f6', color: '#fff'}}
					>
						Notify Me!
					</Button>
				</DialogActions>
			</Dialog>
		</FormControl>
	)
}
